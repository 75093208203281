@font-face {
  font-family: 'Roboto-Medium';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pixelify';
  src: url('./fonts/PixelifySans-Regular.ttf') format('truetype');
}

html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
  height: 100%;
  width: 100%; 
  margin: 0; 
  padding: 0; 
  position: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rain {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.raindrop {
  position: absolute;
  bottom: 100%;
  background: linear-gradient(to bottom, rgba(173,216,230,0.6), rgba(100,149,237,0.8));
  border-radius: 50%;
  animation: fall linear infinite;
  filter: blur(1px);
}

@keyframes fall {
  to {
    transform: translateY(calc(100vh + 10px));
  }
}

.logo {
  font-weight: 700;
  letter-spacing: 2px;
  font-family: 'Roboto-Medium', sans-serif;
  display: inline-block;
}

.pixelify {
  font-family: "Pixelify", sans-serif !important;
}
